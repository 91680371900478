<template>
    <div class="data-content">
        <div class="left-content">
            <div class="left-inner">
                <div class="shadow-top">
                    <div class="top-item">
                        <div class="top-left shadow-3">
                            <div class="user-info">
                                <img v-if="stepOneData.avatar" :src="stepOneData.avatar" alt="" />
                                <div class="middle-info">
                                    <span class="user-live-name">{{ stepOneData.nickname }}</span>
                                    <span class="user-live-like">0本场点赞</span>
                                </div>
                                <div class="right-btn">关注</div>
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="watch-num shadow-3">{{ watchNum }}</div>
                        </div>
                    </div>
                    <!--                    <div class="tag-content">-->
                    <!--                        <div class="tag-item shadow-3" v-for="(item,index) in tagList" :key="index">-->
                    <!--                            {{item.title}}-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="preview-content" v-if="showIndex">
                        <img :src="baseInfo.goods_info_patch[showIndex - 1].goods_live_patch" alt="" />
                        <!--                        <div class="preview-text">{{showGoods}}</div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="right-content">
            <div class="right-top">
                <div class="top-tips">直播背景墙</div>
                <div class="upload-content">
                    <el-upload
                        class="img-uploader"
                        :action="uploadCover"
                        :headers="headersParams"
                        name="file"
                        accept=".jpg, .jpeg, .png"
                        :show-file-list="false"
                        :on-success="handleCoverSuccess"
                        :before-upload="beforeCoverUpload"
                    >
                        <div class="add-box">
                            <i class="el-icon-plus add-icon"></i>
                        </div>
                        <div class="img-box" v-if="baseInfo.live_background">
                            <img :src="baseInfo.live_background" />
                            <div class="shadow-box" @click="clearImg">删除</div>
                        </div>
                    </el-upload>
                    <span class="upload-tips">（建议尺寸：1080*1920px，图片大小不得超过3MB）</span>
                </div>
            </div>
            <div class="right-bottom">
                <div class="bottom-btn">
                    <el-button type="primary" size="mini" @click="addPic">添加商品</el-button>
                </div>
                <el-table
                    :data="baseInfo.goods_info_patch"
                    :class="baseInfo.goods_info_patch.length == 0 ? '' : 'main-table'"
                    :height="baseInfo.goods_info_patch.length == 0 ? 60 : 522"
                    :header-cell-style="{
                        fontWeight: 'normal',
                        height: '60px',
                        color: '#fff',
                        fontSize: '14px',
                        background: '#0C0E3F',
                    }"
                    :cell-style="{ fontSize: '14px', color: '#fff', height: '140px', background: '#070932' }"
                >
                    <el-table-column label="商品信息">
                        <template slot-scope="scope">
                            <el-select
                                v-model="scope.row.goods_student_id"
                                placeholder="请选择"
                                @change="(value) => selectGoods(scope.$index, value)"
                                class="goods-select"
                                popper-class="goods-popper"
                            >
                                <el-option
                                    v-show="isSelectProduct(item)"
                                    v-for="(item, index) in multipleSelection"
                                    :key="item.goods_id"
                                    :label="item.getGoodsInfo.goods_name"
                                    :value="item.goods_id"
                                >
                                </el-option>
                                <el-option
                                    :value="null"
                                    label="暂无数据"
                                    v-if="goods_selectList.length === multipleSelection.length"
                                    :disabled="true"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_sku" label="上传贴片" align="center" width="350">
                        <template slot-scope="scope">
                            <div class="upload-content table-upload">
                                <el-upload
                                    class="img-uploader"
                                    :action="uploadCover"
                                    :headers="headersParams"
                                    name="file"
                                    accept=".jpg, .jpeg, .png"
                                    :show-file-list="false"
                                    :on-success="(value) => handleGoodsImg(scope.$index, value)"
                                    :before-upload="beforeGoodsImgUpload"
                                >
                                    <div class="add-box">
                                        <i class="el-icon-plus add-icon"></i>
                                    </div>
                                    <div class="img-box img-100" v-if="scope.row.goods_live_patch">
                                        <img :src="scope.row.goods_live_patch" />
                                        <div class="shadow-box">重新上传</div>
                                    </div>
                                </el-upload>
                                <div class="img-tips">图片尺寸限制为1:1，大小不可超过3MB</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="op-content">
                                <div class="op-btn" @click="previewGoods(scope.row, scope.$index)">预览</div>
                                <div class="op-btn danger" @click="delBtn(scope.$index)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="empty-data" v-if="baseInfo.goods_info_patch.length == 0">
                    <img class="no-data" src="../../../assets/image/studentCoupon/no_data_coupon.png" alt="" />
                    <div class="no-data-tips">暂无贴片</div>
                </div>
            </div>
            <div class="download-box" v-if="fileUrl">
                <img src="../../../assets/image/student/resource.png" alt="" />
                <span class="download-text">直播间素材</span>
                <el-button type="primary" size="medium" @click="downloadFile">下载素材</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { liveRoom_getScriptFile, live_script_intro_list, live_script_goods } from "@/utils/apis";

export default {
    name: "liveConfig",
    props: ["stepOneData", "stepTwoData", "fileUrl"],
    data() {
        return {
            tagList: [
                {
                    title: "减肥",
                },
            ],
            watchNum: 0,
            baseInfo: {
                live_background: "",
                goods_info_patch: [],
            },
            uploadCover: "api/stu/liveRoom/upload",
            headersParams: {
                Authorization: localStorage.getItem("studentToken"),
            },
            goodsIntro: [],
            goodsList: [],
            multipleSelection: [],
            goods_selectList: [],
            showIndex: "",
            showGoods: "",
        };
    },
    mounted() {
        this.promiseAll();
    },
    methods: {
        promiseAll() {
            let p1 = this.getGoodsIntro();
            let p2 = this.getGoodsList();
            Promise.all([p1, p2]).then(([intro, goods]) => {
                this.goodsIntro = intro.data;

                this.goodsList = goods.data.list;

                this.goodsIntro.forEach((item, index) => {
                    let obj = this.goodsList.find((goodsItem) => goodsItem.goods_id == item.goods_id);
                    obj.goods_student_id = item.id;
                    if (obj) {
                        this.multipleSelection.push(obj);
                    }
                });

                if (this.$route.query.id) {
                    this.baseInfo = this.stepTwoData;
                    this.baseInfo.goods_info_patch.forEach((item, index) => {
                        this.selectGoods(index, item.goods_student_id);
                    });
                }
            });
        },
        getGoodsList() {
            return new Promise((resolve, reject) => {
                let script_id = localStorage.getItem("scriptId");
                let param = {
                    script_id: script_id,
                };
                live_script_goods(param)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getGoodsIntro() {
            return new Promise((resolve, reject) => {
                let param = {
                    train_id: localStorage.getItem("train_id"),
                };
                live_script_intro_list(param)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        handleCoverSuccess(res, file) {
            if (res.code === 200) {
                this.baseInfo.live_background = res.data.url;
                this.$message.success(res.msg);
            } else {
                this.$message.error("图片上传失败，请稍后再试~");
            }
        },
        beforeCoverUpload(file) {
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
                this.$message.error("上传图片大小不能超过 3MB!");
            } else {
                const isSize = new Promise((resolve, reject) => {
                    const height = 1920;
                    const width = 1080;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    () => {
                        this.$message.warning("图片尺寸限制为1080px x 1920px，大小不可超过3MB");
                        return Promise.reject();
                    }
                );
                return isSize;
            }
        },
        handleGoodsImg(index, res) {
            if (res.code === 200) {
                this.baseInfo.goods_info_patch[index].goods_live_patch = res.data.url;
                this.$message.success(res.msg);
            } else {
                this.$message.error("图片上传失败，请稍后再试~");
            }
        },
        beforeGoodsImgUpload(file) {
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
                this.$message.error("上传图片大小不能超过 3MB!");
            } else {
                const isSize = new Promise((resolve, reject) => {
                    const width = 100;
                    const height = 100;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        // const valid = img.width === width && img.height === height;
                        const valid = img.width === img.height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    () => {
                        this.$message.warning("图片尺寸限制为1:1，大小不可超过3MB");
                        return Promise.reject();
                    }
                );
                return isSize;
            }
        },
        clearImg() {
            this.baseInfo.live_background = "";
        },
        downloadFile() {
            window.open(this.fileUrl, "_blank");
        },
        addPic() {
            let obj = {
                goods_student_id: "",
                goods_live_patch: "",
            };
            this.baseInfo.goods_info_patch.push(obj);
        },
        selectGoods(index, val) {
            let obj = this.multipleSelection.find((item) => item.goods_id == val);
            this.goods_selectList[index] = obj;
        },
        isSelectProduct(item) {
            if (this.goods_selectList.indexOf(item) != -1) {
                return false;
            } else {
                return true;
            }
        },
        previewGoods(row, index) {
            if (!row.goods_student_id) {
                return this.$message.warning("请先选择商品");
            }
            if (!row.goods_live_patch) {
                return this.$message.warning("请上传贴片");
            }
            this.showIndex = index + 1;
            this.showGoods = this.multipleSelection.find((item) => item.goods_id == row.goods_student_id).getGoodsInfo.goods_name;
        },
        delBtn(index) {
            if (this.showIndex == index) {
                this.showIndex = "";
            }
            this.baseInfo.goods_info_patch.splice(index, 1);
            this.goods_selectList.splice(index, 1);
        },
        confirmForm() {
            if (this.multipleSelection.length == 0) {
                return this.$message.warning("您还未添加商品，请回到选品处添加商品！");
            }
            if (!this.baseInfo.live_background) {
                return this.$message.warning("请添加直播背景墙！");
            }
            if (this.goods_selectList.length == 0) {
                return this.$message.warning("请添加商品！");
            }
            this.$emit("stepActiveAdd", this.baseInfo);
            localStorage.setItem("liveConfigInfo", JSON.stringify(this.baseInfo));
        },
    },
};
</script>

<style scoped lang="scss">
.data-content {
    display: flex;
    height: 100%;
    box-sizing: content-box;
}

.left-content {
    height: 100%;
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background-image: url("../../../assets/image/student/live_background.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.left-inner {
    padding: 20px;
    height: calc(100% - 40px);
}

.top-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shadow-3 {
    background: rgba(0, 0, 0, 0.3);
}

.shadow-5 {
    background: rgba(0, 0, 0, 0.5);
}

.top-left {
    width: 202px;
    height: 44px;
    border-radius: 22px;
}

.user-info {
    height: 37px;
    padding: 2px;
    display: flex;
    justify-content: space-between;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .middle-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #fff;
        margin: 4px 0 0 7px;

        .user-live-name {
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .user-live-like {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .right-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        width: 48px;
        height: 36px;
        background-color: #fe3266;
        border-radius: 18px;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }

    .grey-btn {
        background: rgba(0, 0, 0, 0.3);
    }
}

.top-right {
    height: 44px;
    display: flex;
    align-items: center;

    .watch-num {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        width: 46px;
        height: 28px;
        color: #fff;
        margin-right: 10px;
    }

    .close-content {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        i {
            font-size: 17px;
            color: #fff;
        }
    }
}

.tag-content {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    .tag-item {
        color: #fff;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        margin-right: 9px;
    }
}

.preview-content {
    margin-top: 12px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    img {
        width: 30%;
        height: auto;
    }
}

.right-content {
    flex: 1;
    margin-left: 20px;

    .top-tips {
        width: 80px;
        color: #fff;
        position: relative;

        &:before {
            content: "*";
            color: #ff3e6c;
            margin-right: 4px;
            position: absolute;
            right: -10px;
            top: 2px;
        }
    }
}

.upload-content {
    display: flex;
    margin-top: 12px;
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    justify-content: flex-start;

    &.table-upload {
        justify-content: center;

        .img-uploader {
            width: 100px;
            height: 100px;
        }
    }

    .upload-tips {
        color: #fff;
        opacity: 0.2;
        display: flex;
        align-items: center;
        margin: 0 96px 0 20px;
    }

    .img-box {
        width: 108px;
        height: 192px;
        position: relative;

        &.img-100 {
            width: 100px;
            height: 100px;

            img {
                width: 100px;
                height: 100px;
            }
        }

        .shadow-box {
            position: absolute;
            bottom: 0;
            background: #000000;
            opacity: 0.6;
            border-radius: 4px;
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
        }

        img {
            width: 108px;
            height: 192px;
        }
    }

    .img-uploader {
        width: 108px;
        height: 192px;
        background: #0c0e3f;
        border: 1px dashed #262963;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;

        .add-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .add-icon {
                color: #4b45ff;
                font-size: 24px;
            }
        }
    }

    .img-tips {
        position: absolute;
        height: 20px;
        left: 14px;
        color: #ffffff;
        bottom: -30px;
    }
}

.right-bottom {
    margin-top: 30px;
    min-height: 480px;

    .bottom-btn {
        text-align: right;
        margin-bottom: 13px;
    }
}

.main-table {
    background: #070932;
}

.el-table {
    width: 100%;

    &:before {
        content: none;
    }

    ::v-deep .el-table__empty-block {
        display: none;
    }

    ::v-deep .el-table__body-wrapper {
        background: #070932;
    }

    ::v-deep tr.el-table__row:last-child {
        .el-table__cell {
            border-bottom: 1px solid #262963;
        }
    }

    ::v-deep th.el-table__cell.is-leaf {
        border-bottom: none;
    }

    ::v-deep td.el-table__cell {
        border-bottom: none;

        &:first-child {
            border-left: 1px solid #262963;
        }

        &:last-child {
            border-right: 1px solid #262963;
        }
    }

    ::v-deep th.el-table__cell {
        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }

        &.is-leaf {
            border-bottom: none;
        }
    }

    ::v-deep td.el-table__cell {
        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }
    }

    ::v-deep .cell {
        overflow: unset;
    }
}

.download-box {
    display: flex;
    margin-top: 20px;
    align-items: center;

    img {
        width: 26px;
        height: 26px;
    }

    .download-text {
        color: #fff;
        margin: 0 20px 0 10px;
    }
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    .no-data {
        width: 322px;
        height: 271px;
    }

    .no-data-tips {
        margin: 58px 0 34px;
        color: #fff;
    }
}

.goods-select {
    width: 380px;

    ::v-deep .el-input__inner {
        border: unset;
        background: #0c0e3f;
        color: #fff;
    }

    ::v-deep .el-icon-arrow-up:before {
        content: "\e78f";
    }
}

.op-content {
    display: flex;
    justify-content: center;

    .op-btn + .op-btn {
        margin-left: 20px;
    }

    .op-btn {
        width: 76px;
        height: 30px;
        background: rgba(75, 69, 255, 0.1);
        border: 1px solid #4b45ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4b45ff;
        cursor: pointer;

        &.danger {
            border: 1px solid #ff3e6c;
            background: rgba(255, 62, 108, 0.1);
            color: #ff3e6c;
        }
    }
}
</style>
